const PageNotFound = () => {
    return (
        <div className="frame">
            <h2>Ooops... Andy has not built this yet</h2>
            <h4>He might be lazy, do you think we should reprimand him?
                Please vote below, or you may just go back to the page you came from.
            </h4>
            <button>Fire Him!</button>
            <button>Forgive him... this time</button>
        </div>
    );
}
 
export default PageNotFound;