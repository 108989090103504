import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import About from './About/About';
import Navbar from './common_pages/Navbar';
import PageNotFound from './error_pages/404';
import Homepage from './home/Homepage';


function App() {
  return (
    <Router>
    <div className="App">
      <Navbar />
      <br />
      <Switch>
        <Route exact path="/">
          <Homepage />
        </Route>
        <Route path="/about">
          <About />
        </Route>

        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </div>
    </Router>
  );
}

export default App;
