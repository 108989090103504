import './about.css';

const About = () => {
    return (
        <div className="about_page">
            <h1>About ABL</h1>
            <div className="about_body">
                <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nunc vel risus commodo viverra. Pharetra magna ac placerat vestibulum. Eleifend quam adipiscing vitae proin sagittis nisl rhoncus. Commodo ullamcorper a lacus vestibulum sed arcu non odio. Eget nunc lobortis mattis aliquam faucibus purus in massa tempor. Aliquet enim tortor at auctor urna. Sed odio morbi quis commodo odio aenean sed adipiscing diam. Laoreet non curabitur gravida arcu ac tortor dignissim convallis aenean. Lectus quam id leo in vitae. Penatibus et magnis dis parturient montes nascetur ridiculus. Vulputate odio ut enim blandit volutpat maecenas volutpat.
                    <br />
                    <br />
                    Gravida arcu ac tortor dignissim convallis aenean et tortor at. Mattis enim ut tellus elementum. Aliquam ut porttitor leo a diam. Dictum varius duis at consectetur lorem donec massa sapien. Viverra accumsan in nisl nisi scelerisque eu ultrices vitae. Cras semper auctor neque vitae tempus. Massa enim nec dui nunc mattis enim ut. Sit amet risus nullam eget felis eget nunc. Ac ut consequat semper viverra. Faucibus ornare suspendisse sed nisi. Ultricies lacus sed turpis tincidunt id aliquet risus. Fringilla phasellus faucibus scelerisque eleifend donec. Enim blandit volutpat maecenas volutpat blandit. Arcu cursus vitae congue mauris rhoncus aenean vel. Consectetur libero id faucibus nisl tincidunt eget nullam. Venenatis a condimentum vitae sapien pellentesque habitant morbi tristique. Suspendisse in est ante in nibh mauris cursus. Duis tristique sollicitudin nibh sit amet commodo nulla facilisi nullam. Velit dignissim sodales ut eu sem integer vitae. Porttitor rhoncus dolor purus non enim praesent elementum facilisis leo. </p>
            </div>

            <img src="" alt="Andy" className="photos_us" />
            <img src="" alt="Kevin" className="photos_us" />

        </div>
    );
}
 
export default About;