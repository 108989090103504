const LogInTile = () => {
    return (
        <div className="log-in-tile">
            <h2>Welcome to ABL!</h2>
            <p>We invite you to review our app and provide feedback on the process. Please click the button below to head to our signin page.</p>
            <a href="https://0a7lat7umu9rhiaq.rentablapp.com:8443/login"><button className="log-in-button">Free my Time!</button></a>
        </div>
    );
}
 
export default LogInTile;